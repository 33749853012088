<!--  -->
<template>
  <div class="good-item" v-if="dataItem" @click="handleDetail">
    <div class="ivv-media" :class="{ 'unsold': dataItem.selloutImg }">
      <img :width="width + 'px'" v-lazy="dataItem.goodsUrl" data-type="1" />
      <div class="prompt" v-if="dataItem.selloutImg">
        <img :src="dataItem.selloutImg" />
      </div>
    </div>
    <div class="ivv-cont" :style="{ 'min-height': width * 2 / 3 + 'px' }">
      <div class="good-name ellipsis2">{{ dataItem.goodsName }}</div>
      <!-- <div class="delivery-time ellipsis1">(该产品有效期28.09)</div> -->
      <div class="introduce">
        <div class="good-format ellipsis2" v-if="norm">{{ dataItem.specifications }}</div>
        <div class="delivery" v-if="dataItem.brandId === 4">{{ dataItem.deliveryMethod === 1 ? '国内发货' : '澳洲直邮' }}</div>
      </div>
      <div class="good-format ellipsis2" v-if="effect">{{ dataItem.actEffect }}</div>
      <div class="price-box">
        <span>￥</span>
        <span class="discount-price">{{ dataItem.goodsPrice }}</span>
        <van-tag type="primary" round :color="color" v-if="priceText">{{priceText}}</van-tag>
        <span class="original-price" v-if="dataItem.originalPrice">官方价：￥{{ dataItem.originalPrice }}</span>
        <span class="count" v-if="count">x{{ dataItem.goodsCount }}</span>
      </div>
      <div class="in-stock" v-if="stock">{{ dataItem.stockNum > 200 ? '库存充足' : ('剩余库存：' + dataItem.stockNum) }}</div>
      <div class="cus-stepper" v-if="stepper" @click.stop="stopBubble">
        <van-stepper v-model="dataItem.cartCount" :min="0" :max="dataItem.chooseMax" theme="round" button-size="22"
          disable-input @change="onChange" />
        <span class="buy-limit" v-if="limit && dataItem.chooseMax !== 9999">限购{{ dataItem.chooseMax }}份</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductItem',
  props: {
    dataItem: {
      type: Object,
      default() {
        return {}
      }
    },
    width: {
      type: Number,
      default: 200
    },
    norm: {
      type: Boolean,
      default: true
    },
    effect: {
      type: Boolean,
      default: true
    },
    stock: {
      type: Boolean,
      default: true
    },
    stepper: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: '#ff98a0'
    },
    count: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Boolean,
      default: false
    },
    priceText: {
      type: String,
      default: "加价购"
    }
  },
  data() {
    return {
      value: 0
    }
  },
  created() {

  },
  methods: {
    onChange() {
      this.$emit("step-change")
    },
    stopBubble() {

    },
    handleDetail() {
      this.$emit("click-detail", this.dataItem);
    }
  },
  watch: {
    dataItem(newList) {
      console.log(newList)
    }
  }
}
</script>
<style lang='scss' scoped>
.good-item {
  display: flex;
  padding: 14px 10px 20px;
  align-items: center;
  position: relative;

  .ivv-media {
    margin-right: 5px;
  }

  .ivv-cont {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .good-name {
      font-size: 14px;
      margin-bottom: 5px;
    }
    .delivery-time{
      color: #ff98a0;
      padding-bottom: 5px;
    }
    .good-format {
      color: #999999;
      margin-bottom: 5px;
    }

    .introduce {
      display: flex;

      .good-format {
        flex: 1;
      }

      .delivery {
        color: #ffb5bb;
        font-size: 9px;
        padding: 2px 10px 2px 5px;
        margin-left: 10px;
        line-height: 12px;
        position: relative;
        box-sizing: border-box;
        height: 18px;
        border: 1px solid #ff98a1;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          border-top: 8px solid transparent;
          border-right: 8px solid #ff98a1;
          border-bottom: 8px solid transparent;
        }

        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: -1px;
          border-top: 8px solid transparent;
          border-right: 8px solid #fff;
          border-bottom: 8px solid transparent;
        }
      }
    }

    .price-box {
      padding: 5px 0;

      .discount-price {
        font-size: 16px;
      }

      .van-tag {
        padding: 2px 4px;
        font-size: 12px;
        display: inline-block;
        transform: scale(0.75);
      }

      .original-price {
        color: #999999;
        font-size: 10px;
        text-decoration: line-through;
      }

      .count {
        color: #999;
        float: right;
      }
    }

    .in-stock {
      color: #999999;
      font-size: 10px;
    }
  }

  .unsold {
    position: relative;

    .prompt {
      position: absolute;
      top: calc(50% - 30px);
      left: calc(50% - 30px);
      width: 60px;
      height: 60px;
      z-index: 2;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 120px;
      height: 120px;
      background-color: rgba(255, 255, 255, 0.6);
    }
  }
}

.cus-stepper {
  // min-width: 80px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;

  /deep/.van-stepper {
    .van-stepper__minus {
      color: #aaaaaa;
      border-color: #aaaaaa;

      &:after {
        height: 2px;
      }
    }

    .van-stepper__minus--disabled {
      display: none;

      &+input {
        display: none;
      }
    }

    .van-stepper__plus {
      background-color: #ff98a0;
    }
  }

  .buy-limit {
    position: absolute;
    // left: 0;
    right: 0;
    width: 80px;
    text-align: center;
    color: #999999;
    font-size: 8px;
  }
}
</style>